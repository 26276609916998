import { ADDRESS, CART_ITEM_TYPE, DOMAIN } from './common';

export const LINKS: { [linkKey: string]: string } = {
  HOME: 'HOME',
  DASHBOARD: 'DASHBOARD',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SETTINGS: 'SETTINGS',
  PROFILE: 'PROFILE',
  ABOUT_US: 'ABOUT_US',
  CONTACT_US: 'CONTACT_US',
  SIGN_IN: 'SIGN_IN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CREATE_ACCOUNT_PIN: 'CREATE_ACCOUNT_PIN',
  CREATE_ACCOUNT_CONFIRMATION: 'CREATE_ACCOUNT_CONFIRMATION',
  CREATE_ACCOUNT_SECRET_QUESTIONS: 'CREATE_ACCOUNT_SECRET_QUESTIONS',
  CREATE_ACCOUNT_SECRET_QUESTIONS_SKIP: 'CREATE_ACCOUNT_SECRET_QUESTIONS_SKIP',
  CREATE_ACCOUNT_SECRET_ANSWERS: 'CREATE_ACCOUNT_SECRET_ANSWERS',
  ACCOUNT_RECOVERY: 'ACCOUNT_RECOVERY',
  FIO_ADDRESSES_SELECTION: 'FIO_ADDRESSES_SELECTION',
  FIO_ADDRESSES_CUSTOM_SELECTION: 'FIO_ADDRESSES_CUSTOM_SELECTION',
  FIO_ADDRESSES: 'FIO_ADDRESSES',
  FIO_ADDRESSES_SETTINGS: 'FIO_ADDRESSES_SETTINGS',
  FIO_ADDRESS_SIGNATURES: 'FIO_ADDRESS_SIGNATURES',
  FIO_ADDRESS_SIGN: 'FIO_ADDRESS_SIGN',
  FIO_ADDRESS_SIGN_CONFIRMATION: 'FIO_ADDRESS_SIGN_CONFIRMATION',
  FIO_ADDRESS_OWNERSHIP: 'FIO_ADDRESS_OWNERSHIP',
  FIO_ADDRESS_OWNERSHIP_CONFIRMATION: 'FIO_ADDRESS_OWNERSHIP_CONFIRMATION',
  FIO_DOMAINS_SELECTION: 'FIO_DOMAINS_SELECTION',
  FIO_DOMAINS: 'FIO_DOMAINS',
  FIO_DOMAIN: 'FIO_DOMAIN',
  FIO_DOMAINS_SETTINGS: 'FIO_DOMAINS_SETTINGS',
  FIO_DOMAIN_RENEW: 'FIO_DOMAIN_RENEW',
  FIO_DOMAIN_OWNERSHIP: 'FIO_DOMAIN_OWNERSHIP',
  FIO_DOMAIN_OWNERSHIP_CONFIRMATION: 'FIO_DOMAIN_OWNERSHIP_CONFIRMATION',
  FIO_DOMAIN_STATUS_CHANGE: 'FIO_DOMAIN_STATUS_CHANGE',
  FIO_DOMAIN_STATUS_CHANGE_CONFIRMATION:
    'FIO_DOMAIN_STATUS_CHANGE_CONFIRMATION',
  FIO_REQUESTS: 'FIO_REQUESTS',
  FIO_WALLET: 'FIO_WALLET',
  FIO_WALLET_DETAILS: 'FIO_WALLET_DETAILS',
  STAKE: 'STAKE',
  UNSTAKE: 'UNSTAKE',
  SEND: 'SEND',
  SEND_CONFIRMATION: 'SEND_CONFIRMATION',
  FIO_TOKENS_REQUEST: 'FIO_TOKENS_REQUEST',
  FIO_TOKENS_REQUEST_CONFIRMATION: 'FIO_TOKENS_REQUEST_CONFIRMATION',
  REJECT_FIO_REQUEST: 'REJECT_FIO_REQUEST',
  REJECT_FIO_REQUEST_CONFIRMATION: 'REJECT_FIO_REQUEST_CONFIRMATION',
  CANCEL_FIO_REQUEST: 'CANCEL_FIO_REQUEST',
  CANCEL_FIO_REQUEST_CONFIRMATION: 'CANCEL_FIO_REQUEST_CONFIRMATION',
  PAYMENT_DETAILS: 'PAYMENT_DETAILS',
  PAYMENT_DETAILS_CONFIRMATION: 'PAYMENT_DETAILS_CONFIRMATION',
  LINK_TOKEN_LIST: 'LINK_TOKEN_LIST',
  ADD_TOKEN: 'ADD_TOKEN',
  ADD_TOKEN_CONFIRMATION: 'ADD_TOKEN_CONFIRMATION',
  TOKENS: 'TOKENS',
  IMPORT_WALLET: 'IMPORT_WALLET',
  NFT_VALIDATION: 'NFT_VALIDATION',
  WRAP_TOKENS: 'WRAP_TOKENS',
  WRAP_TOKENS_CONFIRMATION: 'WRAP_TOKENS_CONFIRMATION',
  WRAP_DOMAIN: 'WRAP_DOMAIN',
  WRAP_DOMAIN_CONFIRMATION: 'WRAP_DOMAIN_CONFIRMATION',
  UNWRAP_DOMAIN: 'UNWRAP_DOMAIN',
  UNWRAP_DOMAIN_CONFIRMATION: 'UNWRAP_DOMAIN_CONFIRMATION',
  UNWRAP_TOKENS: 'UNWRAP_TOKENS',
  UNWRAP_TOKENS_CONFIRMATION: 'UNWRAP_TOKENS_CONFIRMATION',
  GOVERNANCE: 'GOVERNANCE',
  PROTOCOL_UPDATES: 'PROTOCOL_UPDATES',
  ADMIN_USERS: 'ADMIN_USERS',
  ADMIN_HOME: 'ADMIN_HOME',
  ADMIN_ORDERS: 'ADMIN_ORDERS',
  ADMIN_REGULAR_USERS: 'ADMIN_REGULAR_USERS',
  ADMIN_PROFILE: 'ADMIN_PROFILE',
  ADMIN_ACCOUNTS: 'ADMIN_ACCOUNTS',
  ADMIN_API_URLS: 'ADMIN_API_URLS',
  ADMIN_PARTNERS: 'ADMIN_PARTNERS',
  ADMIN_DEFAULTS: 'ADMIN_DEFAULTS',
  IS_NEW_USER: 'IS_NEW_USER',
  CART: 'CART',
  CHECKOUT: 'CHECKOUT',
  PURCHASE: 'PURCHASE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  COOKIE_NOTICE: 'COOKIE_NOTICE',
  NOT_FOUND: 'NOT_FOUND',
  ERROR: 'ERROR',
  ORDERS: 'ORDERS',
  ORDER_DETAILS: 'ORDER_DETAILS',
  ORDER_INVOICE: 'ORDER_INVOICE',
  FIO_AFFILIATE_PROGRAM_LANDING: 'FIO_AFFILIATE_PROGRAM_LANDING',
  FIO_AFFILIATE_PROGRAM_ENABLED: 'FIO_AFFILIATE_PROGRAM_ENABLED',
  WRAP_STATUS_WRAP_TOKENS: 'WRAP_STATUS_WRAP_TOKENS',
  WRAP_STATUS_UNWRAP_TOKENS: 'WRAP_STATUS_UNWRAP_TOKENS',
  WRAP_STATUS_WRAP_DOMAINS: 'WRAP_STATUS_WRAP_DOMAINS',
  WRAP_STATUS_UNWRAP_DOMAINS: 'WRAP_STATUS_UNWRAP_DOMAINS',
  UNAVAILABLE: 'UNAVAILABLE',
  TWITTER_HANDLE: 'TWITTER_HANDLE',
  FIO_SOCIAL_MEDIA_LINKS: 'FIO_SOCIAL_MEDIA_LINKS',
  FIO_SOCIAL_MEDIA_LINKS_ADD: 'FIO_SOCIAL_MEDIA_LINKS_ADD',
  FIO_SOCIAL_MEDIA_LINKS_EDIT: 'FIO_SOCIAL_MEDIA_LINKS_EDIT',
  FIO_SOCIAL_MEDIA_LINKS_DELETE: 'FIO_SOCIAL_MEDIA_LINKS_DELETE',
};

export const LINK_LABELS: { [linkKey: string]: string } = {
  [LINKS.HOME]: 'Home',
  [LINKS.DASHBOARD]: 'Dashboard',
  [LINKS.NOTIFICATIONS]: 'Notifications',
  [LINKS.SETTINGS]: 'Settings',
  [LINKS.PROFILE]: 'My profile',
  [LINKS.ABOUT_US]: 'About Us',
  [LINKS.CONTACT_US]: 'Contact Us',
  [LINKS.FIO_ADDRESSES_SELECTION]: 'FIO Handles Selection',
  [LINKS.FIO_DOMAINS_SELECTION]: 'FIO Domains Selection',
  [LINKS.FIO_ADDRESSES]: 'FIO Handles',
  [LINKS.FIO_DOMAINS]: 'FIO Domains',
  [LINKS.FIO_REQUESTS]: 'FIO Requests',
  [LINKS.FIO_WALLET]: 'FIO Wallet',
  [LINKS.TOKENS]: 'FIO Tokens',
  [LINKS.FIO_AFFILIATE_PROGRAM_LANDING]: 'FIO Affiliate Program',
  [LINKS.FIO_AFFILIATE_PROGRAM_ENABLED]: 'FIO Affiliate Program',
  [LINKS.GOVERNANCE]: 'Governance',
  [LINKS.PROTOCOL_UPDATES]: 'Protocol Updates',
  [LINKS.ADMIN_USERS]: 'Admin Users',
  [LINKS.ADMIN_HOME]: 'Home',
  [LINKS.ADMIN_ORDERS]: 'Orders',
  [LINKS.ADMIN_REGULAR_USERS]: 'Users',
  [LINKS.ADMIN_PROFILE]: 'Profile',
  [LINKS.ADMIN_ACCOUNTS]: 'Accounts',
  [LINKS.ADMIN_API_URLS]: 'API Urls',
  [LINKS.ADMIN_PARTNERS]: 'Partners',
  [LINKS.ADMIN_DEFAULTS]: 'Defaults',
  [LINKS.UNAVAILABLE]: 'Unavailable',
};

export const APP_TITLE = 'FIO App';
export const LINK_TITLES: { [linkKey: string]: string } = {
  [LINKS.HOME]: 'Home',
  [LINKS.DASHBOARD]: 'My FIO',
  [LINKS.SETTINGS]: 'Settings',
  [LINKS.SIGN_IN]: 'Sign in',
  [LINKS.RESET_PASSWORD]: 'Reset Password',
  [LINKS.CREATE_ACCOUNT]: 'Create Account',
  [LINKS.CREATE_ACCOUNT_PIN]: 'Create Account Pin',
  [LINKS.CREATE_ACCOUNT_CONFIRMATION]: 'Create Account Confirmation',
  [LINKS.CREATE_ACCOUNT_SECRET_QUESTIONS]: 'Create Account Secret Questions',
  [LINKS.CREATE_ACCOUNT_SECRET_QUESTIONS_SKIP]:
    'Create Account Secret Questions Skip',
  [LINKS.CREATE_ACCOUNT_SECRET_ANSWERS]: 'Create Account Secret Answers',
  [LINKS.IS_NEW_USER]: 'Confirm email',
  [LINKS.ACCOUNT_RECOVERY]: 'Recover Account',
  [LINKS.FIO_ADDRESSES_SELECTION]: 'FIO Handle Registration',
  [LINKS.FIO_ADDRESSES_CUSTOM_SELECTION]:
    'FIO Handle Registration on Custom Domain',
  [LINKS.FIO_ADDRESSES]: 'FIO Handles',
  [LINKS.FIO_ADDRESSES_SETTINGS]: 'FIO Handle Settings',
  [LINKS.FIO_ADDRESS_OWNERSHIP]: 'FIO Handle Ownership Transfer',
  [LINKS.FIO_ADDRESS_OWNERSHIP_CONFIRMATION]:
    'FIO Handle Ownership Transfer Confirmation',
  [LINKS.FIO_DOMAINS_SELECTION]: 'Domain Registration',
  [LINKS.FIO_DOMAINS]: 'Domains',
  [LINKS.FIO_DOMAIN]: 'Domain',
  [LINKS.FIO_DOMAINS_SETTINGS]: 'Domain Settings',
  [LINKS.FIO_DOMAIN_RENEW]: 'Renew Domain',
  [LINKS.FIO_DOMAIN_OWNERSHIP]: 'Domain Ownership Transfer',
  [LINKS.FIO_DOMAIN_OWNERSHIP_CONFIRMATION]:
    'Domain Ownership Transfer Confirmation',
  [LINKS.FIO_DOMAIN_STATUS_CHANGE]: 'Domain Status Change',
  [LINKS.FIO_DOMAIN_STATUS_CHANGE_CONFIRMATION]:
    'Domain Status Change Confirmation',
  [LINKS.CART]: 'Shopping Cart',
  [LINKS.CHECKOUT]: 'Checkout',
  [LINKS.PURCHASE]: 'Purchase Confirmation',
  [LINKS.TOKENS]: 'Wallets',
  [LINKS.IMPORT_WALLET]: 'Import FIO Wallet',
  [LINKS.FIO_WALLET]: 'Wallet Transactions',
  [LINKS.FIO_WALLET_DETAILS]: 'Wallet Details',
  [LINKS.STAKE]: 'Stake FIO Tokens',
  [LINKS.UNSTAKE]: 'Unstake FIO Tokens',
  [LINKS.SEND]: 'Send FIO Tokens',
  [LINKS.SEND_CONFIRMATION]: 'Send FIO Tokens Confirmation',
  [LINKS.FIO_TOKENS_REQUEST]: 'FIO Request',
  [LINKS.FIO_TOKENS_REQUEST_CONFIRMATION]: 'FIO Request Confirmation',
  [LINKS.REJECT_FIO_REQUEST]: 'Reject FIO Request',
  [LINKS.REJECT_FIO_REQUEST_CONFIRMATION]: 'Reject FIO Request Confirmation',
  [LINKS.CANCEL_FIO_REQUEST]: 'Cancel FIO Request',
  [LINKS.CANCEL_FIO_REQUEST_CONFIRMATION]: 'Cancel FIO Request Confirmation',
  [LINKS.PAYMENT_DETAILS]: 'Send FIO Data',
  [LINKS.PAYMENT_DETAILS_CONFIRMATION]: 'Send FIO Data Confirmation',
  [LINKS.FIO_ADDRESS_SIGNATURES]: 'NFT Signatures',
  [LINKS.FIO_ADDRESS_SIGN]: 'Sign NFT',
  [LINKS.FIO_ADDRESS_SIGN_CONFIRMATION]: 'Sign NFT Confirmation',
  [LINKS.LINK_TOKEN_LIST]: 'Linked Token List',
  [LINKS.ADD_TOKEN]: 'Link Token',
  [LINKS.ADD_TOKEN_CONFIRMATION]: 'Link Token Confirmation',
  [LINKS.NFT_VALIDATION]: 'Validate NFT Signature',
  [LINKS.WRAP_TOKENS]: 'Wrap FIO Tokens',
  [LINKS.WRAP_TOKENS_CONFIRMATION]: 'Wrap FIO Tokens Confirmation',
  [LINKS.WRAP_DOMAIN]: 'Wrap FIO Domain',
  [LINKS.WRAP_DOMAIN_CONFIRMATION]: 'Wrap FIO Domain Confirmation',
  [LINKS.UNWRAP_DOMAIN]: 'Unwrap FIO Domain',
  [LINKS.UNWRAP_DOMAIN_CONFIRMATION]: 'Unwrap FIO Domain Confirmation',
  [LINKS.UNWRAP_TOKENS]: 'Unwrap FIO Tokens',
  [LINKS.UNWRAP_TOKENS_CONFIRMATION]: 'Unwrap FIO Tokens Confirmation',
  [LINKS.PRIVACY_POLICY]: 'Privacy Policy',
  [LINKS.TERMS_OF_SERVICE]: 'Terms of Service',
  [LINKS.COOKIE_NOTICE]: 'Cookie Notice',
  [LINKS.NOT_FOUND]: 'Not Found',
  [LINKS.ERROR]: 'Error',
  [LINKS.ORDERS]: 'Orders',
  [LINKS.ORDER_DETAILS]: 'Order Details',
  [LINKS.ORDER_INVOICE]: 'Order Invoice',
  [LINKS.FIO_AFFILIATE_PROGRAM_LANDING]: 'Affiliate Program',
  [LINKS.FIO_AFFILIATE_PROGRAM_ENABLED]: 'Affiliate Program Enabled',
  [LINKS.WRAP_STATUS_WRAP_TOKENS]: 'Wrap FIO Tokens Status',
  [LINKS.WRAP_STATUS_UNWRAP_TOKENS]: 'Unwrap FIO Tokens Status',
  [LINKS.WRAP_STATUS_WRAP_DOMAINS]: 'Wrap FIO Domains Status',
  [LINKS.WRAP_STATUS_UNWRAP_DOMAINS]: 'Unwrap FIO Domains Status',
  [LINKS.UNAVAILABLE]: 'Unavailable',
  [LINKS.TWITTER_HANDLE]: 'Your Twitter Handle Can Now Receive Crypto',
  [LINKS.FIO_SOCIAL_MEDIA_LINKS]: 'Social Media Links',
  [LINKS.FIO_SOCIAL_MEDIA_LINKS_ADD]: 'Add Social Media Links',
  [LINKS.FIO_SOCIAL_MEDIA_LINKS_EDIT]: 'Edit Social Media Links',
  [LINKS.FIO_SOCIAL_MEDIA_LINKS_DELETE]: 'Delete Social Media Links',
};

export const fioNameLabels: { [fioNameType: string]: string } = {
  [ADDRESS]: 'FIO Handle',
  [DOMAIN]: 'FIO Domain',
};

export const TRANSFER_PAGE_LINK = {
  [DOMAIN]: LINKS.FIO_DOMAIN_OWNERSHIP,
  [ADDRESS]: LINKS.FIO_ADDRESS_OWNERSHIP,
};

export const TRANSFER_PAGE_CONFIRMATION_LINK = {
  [DOMAIN]: LINKS.FIO_DOMAIN_OWNERSHIP_CONFIRMATION,
  [ADDRESS]: LINKS.FIO_ADDRESS_OWNERSHIP_CONFIRMATION,
};

export const CART_ITEM_DESCRIPTOR = {
  [CART_ITEM_TYPE.ADDRESS]: 'FIO Handle Registration',
  [CART_ITEM_TYPE.DOMAIN]: 'FIO Domain Registration',
  [CART_ITEM_TYPE.ADDRESS_WITH_CUSTOM_DOMAIN]:
    'FIO Handle and Domain Registration',
  [CART_ITEM_TYPE.DOMAIN_RENEWAL]: 'FIO Domain Renewal',
  [CART_ITEM_TYPE.ADD_BUNDLES]: 'Add Bundled Transactions',
};
